var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default", function () {
        return [
          _vm._v(
            _vm._s(
              _vm.hour
                ? _vm.hourString +
                    ":" +
                    _vm.minuteString +
                    ":" +
                    _vm.secondString
                : _vm.minuteString + ":" + _vm.secondString
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }