<template>
  <div>
    <slot>{{hour ? hourString + ':' + minuteString + ':' + secondString : minuteString + ':' + secondString}}</slot>
  </div>
</template>

<script>
export default {
  props: {
    endTime: {type: Number, default: 0},
    showDay: {type: Boolean, default: false}
  },
  data () {
    return {
      day: 0, hour: 0, minute: 0, second: 0, timer: null
    }
  },
  computed: {
    dayString() {
      return this.formatNum(this.day);
    },
    hourString () {
      return this.formatNum(this.hour);
    },
    minuteString () {
      return this.formatNum(this.minute);
    },
    secondString () {
      return this.formatNum(this.second);
    }
  },
  watch: {
    endTime() {
      this.countDowm();
    }
  },
  mounted() {
    this.countDowm();
  },
  methods: {
    countDowmInit() {
      const nowtime = Date.now();
      const diff = this.endTime - nowtime;

      if (diff > 0) {
        if (this.showDay) {
          this.day = Math.floor(diff / (1000 * 60 * 60 *24));
          this.hour = Math.floor(diff / (1000 * 60 * 60) % 24);
        } else {
          this.hour = Math.floor(diff / (1000 * 60 * 60));
        }

        this.minute = Math.floor(diff / (1000 * 60) % 60);
        this.second = Math.floor(diff / 1000 % 60);
      } else {
        if (this.timer) clearInterval(this.timer);
        this.day = 0;
        this.hour = 0;
        this.minute = 0;
        this.second = 0;
      }
      this.$emit('getTime', {
        day: this.dayString, hour: this.hourString, minute: this.minuteString, second: this.secondString,
        isEnd: !this.day && !this.hour && !this.minute && !this.second
      });
    },
    countDowm () {
      if (this.timer) clearInterval(this.timer);
      this.countDowmInit();

      this.timer = setInterval(() => {
        this.countDowmInit();
      }, 1000);
    },
    formatNum (num) {
      return num < 10 ? '0' + num : '' + num;
    }
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  }
}
</script>