// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.box .el-input input {\n  height: 56px;\n  border-color: #3fb265;\n}\n.box .el-button {\n  width: 100%;\n  height: 56px;\n  display: block;\n  padding: 0;\n  background: #3fb265;\n  border-color: #3fb265;\n}\n.box .el-button span {\n  line-height: normal;\n  font-size: 18px;\n}\n.box .el-button.countDown {\n  width: 120px;\n}\n.box .el-button.countDown span {\n  font-size: 14px;\n}\n.box .el-checkbox {\n  margin-top: 30px;\n}\n.box .is-checked + .el-checkbox__label {\n  color: #3fb265;\n}\n.box .is-checked .el-checkbox__inner {\n  background: #3fb265;\n  border-color: #3fb265;\n}\n@media screen and (max-width: 1500px) {\n.box .el-input input {\n    height: 45px;\n}\n.box .el-checkbox {\n    display: block;\n    margin-top: 20px;\n}\n.box .el-button {\n    height: 44px;\n    display: block;\n}\n}\n.rule-cont {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 30px;\n}\n.rule-cont .el-checkbox {\n  margin-top: 0px;\n  margin-right: 6px;\n}\n.rule-cont span {\n  font-size: 12px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
