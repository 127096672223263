<template>
  <div class="login">
    <!-- <img
      class="bg"
      src="https://img.hzanchu.com/acimg/a0af454e1a24e356241d29a8585716d0.png"
    > -->
    <div
      class="box mask"
      :class="showCaptcha ? 'box-height' : null"
    ></div>
    <div
      class="box content"
      :class="showCaptcha ? 'box-height' : null"
    >
      <p>{{ subjectObj.name }}</p>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        label-position="left"
        autocomplete="on"
      >
        <el-form-item
          prop="username"
          class="username-box"
        >
          <el-input
            ref="username"
            v-model="loginForm.username"
            type="text"
            placeholder="手机号"
          />
        </el-form-item>

        <!-- <el-form-item prop="password" class="password-box">
          <div class="autoCode">
            <el-input v-model="loginForm.password" class="autoCode-left" type="text" placeholder="验证码" />
            <div>
              <CountDown showDay :endTime="endTime" @getTime="getTime">
                <el-button slot class="countDown" @click="sendCode">{{sending ? '已发送 ' + content : '获取验证码'}}</el-button>
              </CountDown>
            </div>
          </div>
        </el-form-item> -->

        <el-form-item
          prop="password"
          class="password-box"
        >
          <!-- <input style="position: absolute; left: -10000px;" type="text" name="password"> -->
          <el-input
            v-model="loginForm.password"
            type="password"
            name="password"
            placeholder="密码"
            show-password
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
        <div class="login-config-container">
          <el-checkbox
            v-model="loginForm.remember"
            style="margin-bottom: 10px"
          >
            记住密码
          </el-checkbox>
          <a
            v-if="subjectObj.applicationShow"
            class="shop-settle"
            @click="toShopSettle"
          >
            我要开店
          </a>
        </div>
        <el-button
          :disabled="!checkValue"
          :loading="loading"
          type="primary"
          :style="{
            background: checkValue ? '#3FB265' : '#ccc',
            borderColor: checkValue ? '#3FB265' : '#ccc'
          }"
          @click.native.prevent="handleLogin"
        >
          登录
        </el-button>
        <div class="rule-cont">
          <el-checkbox
            :value="checkValue"
            @change="checkRule"
          />
          <span>我已阅读同意并接受<span
            style="color: #3fb265; cursor: pointer"
            @click="openRule"
          >{{ subjectObj.agreementTitle }}</span></span>
        </div>
      </el-form>
    </div>
    <!--规则弹窗-->
    <RuleDialog
      :is-show="ruleDialogVisible"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import md5 from 'md5-js';
import { Base64 } from 'js-base64';
import RuleDialog from '@/views/login/ruleDialog.vue';
import { UserModule } from '@/store/modules/user';
// import { Login } from '@/api/types'
// import { isValidUsername } from '@/utils/validate'

import CountDown from '@/components/CountDown/second.vue';
import { validFn } from '@/utils/common';
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  name: 'login',
  components: { CountDown, RuleDialog },
  data() {
    return {
      loginForm: {
        username: this.getLocalStorage().UN,
        password: this.getLocalStorage().PW,
        remember: this.getLocalStorage().RS,
      },
      showCaptcha: true,
      // 验证码图片
      captchaImg: '',
      loginRules: {
        username: [
          {
            validator: validFn.validEmpty, trigger: 'blur', msg: '请输入手机号', required: true,
          },
          {
            validator: validFn.validPhone, trigger: 'blur', msg: '手机号有误', required: true,
          },
        ],
        password: [{
          validator: validFn.validEmpty, trigger: 'blur', msg: '请输入密码', required: true,
        }],
      },
      loading: false,
      ruleDialogVisible: false,
      checkValue: true,

      endTime: 0,
      sending: false,
      content: '',
      subjectObj: {
        name: '网上农博',
        applicationShow: false,
        agreementTitle: '',
        agreementUrl: '',
      },
    };
  },
  created() {
    this.subjectObj = projectConfigDetail;
  },
  mounted() {
    // 判断是否为第二天
    /* let daytimestamp = localStorage.getItem('daytimestamp')
    if (
      new Date(Number(daytimestamp)).toDateString() ===
      new Date().toDateString()
    ) {
      this.showCaptcha = true
      this.getCaptchaImg()
    } */
  },
  methods: {
    sendCode() {
      this.$refs.loginForm.validateField('username', async (valid) => {
        if (valid || this.sending) return;
        const res = await this.$axios.post(this.$api.operation.getCode, { mobile: this.loginForm.username });
        if (res.code === 0) {
          this.endTime = Date.now() + 6 * 60 * 1000;
          this.sending = true;
        }
      });
    },
    toShopSettle() {
      this.$router.push('/shopSettle/steps');
    },
    getTime(date) {
      const { minute, second, isEnd } = date;
      if (isEnd) this.sending = false;
      else this.content = `${minute}分${second}秒`;
    },

    validateUsername(rule, value, callback) {
      if (value.trim() === '') {
        callback(new Error('请填写账号'));
      } else {
        callback();
      }
    },
    validatePassword(rule, value, callback) {
      if (value.trim() === '') {
        callback(new Error('请填写密码'));
      } else if (value.length < 6) {
        // callback(new Error("密码不得低于6位"));
        callback();
      } else {
        callback();
      }
    },
    getLocalStorage() {
      const data = { UN: '', PW: '', RS: false };
      const localStr = localStorage.getItem('localStr');
      if (localStr === undefined || localStr === null) {
        data.UN = '';
        data.PW = '';
        data.RS = false;
      } else {
        data.UN = JSON.parse(localStr).UN;
        data.PW = JSON.parse(localStr).PW;
        data.RS = JSON.parse(localStr).RS;
      }
      return data;
    },
    checkRule(val) {
      this.checkValue = val;
    },
    closeDialog() {
      this.ruleDialogVisible = false;
    },
    openRule() {
      window.open(this.subjectObj.agreementUrl);
    },
    async getCaptchaImg() {
      let res = await this.$axios.post(
        this.$api.code,
        {},
        { responseType: 'blob' },
      );
      const myBlob = new window.Blob([res], { type: 'image/jpeg' });
      this.captchaImg = window.URL.createObjectURL(myBlob);
    },
    async handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          UserModule.Login(this.loginForm).then((data) => {
            this.loading = false;
            if (data.code === 0) {
              // 判断平台是否正确
              // const code = data.data.projectCode;
              // const hostnam = window.location.hostname;
              // if (code && code !== '0000' && +projectConfigDetail.code !== +code && hostnam !== 'localhost') {
              //   this.$message.error('登录失败：平台错误');
              //   localStorage.clear();
              //   return;
              // }
              // 采摘卡，指定子账号不能登录
              if (data.isShopAccount) {
                this.$message.error('此账号暂无登录权限');
                return;
              }
              this.$message.success('登录成功');
              if (this.loginForm.remember) {
                // 记住密码 保存账号密码
                const localObj = {
                  UN: this.loginForm.username, // 账号
                  PW: this.loginForm.password, // 密码
                  RS: this.loginForm.remember, // 是否记住密码
                };
                localStorage.setItem('localStr', JSON.stringify(localObj));
              }
              localStorage.setItem('userInfo', JSON.stringify(data.data));
              localStorage.setItem('appName', 'gys');
              if (data.data.defaultStoreId === 0 || data.data.isNeedMend === 1) {
                this.$router.push(`/store?id=${data.data.suppliersId}&type=add&tipVisible=0&stepsVisible=0`);
              } else if (data.data.financialStatus === 0) {
                this.$router.push('/supplybankAudit?type=add');
              } else {
                let redirect = decodeURIComponent(
                  `${this.$route.query.redirect}`,
                ).toString();
                console.log(redirect, '路由');
                this.$router.replace({
                  path:
                    redirect !== ''
                    && redirect !== 'undefined'
                    && redirect !== '/login'
                      ? redirect
                      : '/',
                });
              }
            }
            console.log('请求成功', data);
          }).catch(() => {
            this.loading = false;
          });
        } else {
          // this.$message.warning('请按照规范输入账号密码')
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.countDown{
  color: #fff;
  span{font-size: 14px!important;}
}

.login-config-container {
  display: flex;
  justify-content: space-between;
  .shop-settle {
    margin-top: 20px;
    line-height: 19px;
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
  }
}
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(https://img.hzanchu.com/acimg/a0af454e1a24e356241d29a8585716d0.png)
    no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .box {
    position: absolute;
    width: 460px;
    height: 520px;
  }
  .box-height {
    height: 580px;
  }
  .mask {
    background: rgba(255, 255, 255, 0.4);
    top: 50%; transform: translateY(-50%);
    right: 20%;
    // margin-left: -240px;
  }
  .content {
    background: #fff;
    top: 50%; transform: translateY(-50%);
    // left: 50%;
    right: 20%;
    // margin-left: -230px;
    padding: 0 60px;
    p {
      font-size: 36px;
      color: #3fb265;
      font-weight: 600;
      text-align: center;
      line-height: 50px;
      margin-top: 34px;
    }
  }
  .username-box {
    display: block;
    margin: 72px auto 0;
  }
  .password-box {
    display: block;
    margin: 30px auto 0;
  }
  .autoCode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .autoCode-left {
      width: 50%;
    }
    img {
      height: 50px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1500px) {
    .box {
      width: 360px;
      height: 400px;
    }
    .box-height {
      height: 490px;
    }

    .content {
      padding: 0 40px;
    }
    .username-box {
      display: block;
      margin: 50px auto 0;
    }
    .password-box {
      display: block;
      margin: 0.2rem auto 0;
    }
  }
}
</style>

<style lang="scss">
.box {
  .el-input {
    input {
      height: 56px;
      border-color: #3fb265;
    }
  }
  .el-button {
    width: 100%;
    height: 56px;
    display: block;
    padding: 0;
    background: #3fb265;
    border-color: #3fb265;
    span {
      line-height: normal;
      font-size: 18px;
    }

    &.countDown{
      width: 120px;
      span{font-size: 14px;}
    }
  }
  .el-checkbox {
    margin-top: 30px;
  }
  .is-checked + .el-checkbox__label {
    color: #3fb265;
  }
  .is-checked {
    .el-checkbox__inner {
      background: #3fb265;
      border-color: #3fb265;
    }
  }

  @media screen and (max-width: 1500px) {
    .el-input {
      input {
        height: 45px;
      }
    }
    .el-checkbox {
      display: block;
      margin-top: 20px;
    }
    .el-button {
      height: 44px;
      display: block;
    }
  }
}
.rule-cont {
  display: flex;
  align-items: center;
  height: 30px;
  .el-checkbox {
    margin-top: 0px;
    margin-right: 6px;
  }
  span {
    font-size: 12px;
  }
}
</style>
